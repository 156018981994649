import { render, staticRenderFns } from "./SubscriptionPlansLink.vue?vue&type=template&id=1a82bd26&scoped=true&"
import script from "./SubscriptionPlansLink.vue?vue&type=script&lang=js&"
export * from "./SubscriptionPlansLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a82bd26",
  null
  
)

export default component.exports