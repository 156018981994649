<template>
  <mcr-button-router-link
    v-if="isButton"
    :label="label"
    :to="subscriptionPlansRoute"
    rel="nofollow"
    @click="onClick"
  ></mcr-button-router-link>
  <router-link v-else :to="subscriptionPlansRoute" rel="nofollow">
    <span @click="onClick" @click.ctrl="onClick" @click.meta="onClick" @click.middle="onClick">{{ label }}</span>
  </router-link>
</template>

<script>
import mcrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {getSubscriptionWallRoute} from '@common/utils/utils.routes';

export default {
  props: {
    label: {type: String, default: 'Get Full Access'},
    isButton: {type: Boolean, default: true},
    recordTitle: {type: String, default: null},
    fromHints: {type: Boolean, default: false},
  },
  computed: {
    subscriptionPlansRoute() {
      return getSubscriptionWallRoute(this.$route.fullPath, this.recordTitle, this.fromHints);
    },
  },
  methods: {
    onClick() {
      this.trackPreSubscriptionAction();
    },
    trackPreSubscriptionAction() {
      AnalyticsAmplitudeHandler.trackPreSubscriptionActionLinkClick(getRoutePageName(this.$route), this.label);
    },
  },
  name: 'SubscriptionPlansLink',
  components: {mcrButtonRouterLink},
};
</script>

<style scoped></style>
